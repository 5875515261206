.homePagePageContainer {
  width: 100%;
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 40px;
  // margin-bottom: 72px;
  // @media only screen and (max-width: 992px) {
  // margin-bottom: 72px;
  // }

  .headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;

    button {
      height: 40px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 13px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border: 1px solid #e0e0e0;
    }

    .active {
      border: 1px solid rgb(0, 184, 64);
      color: rgb(0, 184, 64);
    }

    @media screen and (max-width: 1240px) {
      flex-direction: column-reverse;
    }
  }

  .homePageContainerCol {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .homePageContainer {
      width: 100%;
      // margin-bottom: 120px;
      // min-height: 400px;
      padding: 10px;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 28px;
        align-items: center;
        text-align: center;
        color: #000000;
      }

      .paraContainer {
        margin-bottom: 20px;
        p {
          font-family: "Inter";
          font-style: normal;
          margin: 0px;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #2e2e2e;
        }
      }

      .boxesContainer {
        padding-top: 14px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;

        .cardItem {
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          padding: 20px;
          height: 196px;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #4e4e4e;
            margin: 0;
            padding: 0px 12px;
            text-align: flex-start;
          }

          .statusBadge {
            width: 92px;
            height: 24px;
            background: #ebfae2;
            border-radius: 4px;

            p {
              font-family: "Inter";
              font-style: normal;
              margin: 0;
              padding: 0px 12px;
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
              color: #4f9c20;
            }
          }

          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #1a1919;
            order: 0;
          }

          .withOutStatus {
            padding-top: 22px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: max-content;
    // margin-bottom: 50px;

    .homePageContainerCol {
      width: 80%;
    }
  }
  @media only screen and (max-width: 992px) {
    .homePageContainerCol {
      width: 80%;
    }
  }
}
.btnDiv {
  margin: 0;
  width: 100%;
  position: fixed;
  bottom: 0;

  .twoBtnDiv {
    padding-inline: 28px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    height: 90px;
    position: inherit;
    // bottom: 0px;
    // justify-content: space-between;
    bottom: 0;

    .goBackBtnDiv {
      padding-top: 22px;
      width: 100%;
      margin-left: 15px;

      .goBtn {
        height: 50px;
        width: 134px;
        color: #f8ebeb;
        background-color: #e6e6e6;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        font-size: 20px;
        flex-direction: row;
        // margin-top: 60px;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
          margin-top: 1px;
        }
      }
    }
    .saveBtn {
      color: #2e2e2e;
      background-color: #e6e6e6;
      height: 50px;
      width: 134px;
      border: none;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  @media only screen and (max-width: 425px) {
    .twoBtnDiv {
      padding-inline: 20px;
      margin-bottom: 0;
      .goBtn {
        height: 40px;
        width: 114px;
      }
      .saveBtn {
        height: 40px;
        width: 114px;
      }
    }
  }
}
