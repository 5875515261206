.banner {
  width: 65%;
  padding: 20px;
  background-color: #fee788;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;

  &Header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 8px;

    & > p {
      margin: 0;
      font-weight: 600;
    }

    & > div {
      margin-left: auto;

      & > svg {
        cursor: pointer;
      }
    }
  }

  & > p {
    font-size: 18px;
    color: #2e2e2e;
    margin: 0;
    padding: 0 40px;
    margin-top: -25px;
  }
}
