.formWrapper {
  height: 100%;
  font-size: 16px;

  .form {
    position: relative;
    justify-content: space-between;

    display: flex;
    flex-direction: column;
    height: 78.5vh; /* Full viewport height */

    .label {
      font-size: 20px;
      font-weight: 500;
      line-height: 140%;
      text-transform: capitalize;
      margin-left: -2px;
      margin-bottom: 10px;
      letter-spacing: -0.12px;
    }

    .urlBox {
      position: absolute;
      top: -60px;
      height: 50px;
      width: 600px;
      border: 1px solid #ffb02f;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 1px 4px 1px #0000000f;
      display: flex;
      flex-direction: column;
      //transition: all 0.5s ease-out;

      &.urlBoxExtended {
        top: -90px;
        height: 80px;

        .urlBoxBottom {
          display: flex;
        }
      }

      &Input {
        display: flex;
        align-items: center;
        padding: 0 30px 0 0;
      }

      &Bottom {
        display: none;
        padding: 0px 20px;
        //visibility: hidden;
        //animation: 0.7s fadeIn;
        //animation-fill-mode: both;

        &ClearButton {
          text-decoration: underline;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }

    .textarea {
      padding: 20px;
      min-height: 64px;
      max-height: 200px;
      line-height: 22px;
      position: relative;
      border: 1px solid var(--primary-green, #00b840);
      font-size: 16px;
      font-family: Inter, sans-serif;

      &[disabled] {
        background-color: unset;
      }

      &:focus,
      &:active {
        border: 1px solid var(--primary-green, #00b840);
        outline: none;
        box-shadow: none;
      }

      &Icons {
        height: 64px;
        margin-right: 12px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        img.icon {
          cursor: pointer;
        }

        &Add {
          position: relative; // Establishes positioning context for .content
          display: flex;
          flex-direction: row-reverse; // Align content to the left of the icon
          align-items: center; // Align items at the top
          text-align: right;
          cursor: pointer;

          .content {
            text-align: left;
            position: absolute; // Remove from normal flow
            bottom: 100%; // Position above the parent
            right: 0; // Align to the right edge of the parent
            width: 180px; // Fixed width
            background-color: white;
            padding: 12px 8px 12px 17px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            opacity: 0;
            //transform: translateY(-10px); // Slight shift for animation
            //transition:
            //  opacity 0.3s ease-in-out,
            //  transform 0.3s ease-in-out;
            z-index: 10; // Ensure it appears above other elements
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            pointer-events: none; // Prevent interaction when hidden

            &UploadContainerDisabled > * {
              filter: grayscale(1);
              color: grey;
              cursor: not-allowed;
            }

            &UploadContainerDisabled img {
              filter: opacity(25%);
            }

            &UploadWrapper,
            &AddUrlWrapper {
              display: flex;
              margin: 12px 0;
              align-items: center;

              & > *:hover {
                // TODO: add hover color
              }

              img {
                width: 18px;
                height: 18px;
              }

              .description {
                margin-left: 20px;
                font-weight: 400;
                font-size: 14px;
                //color: #2e2e2e;

                .subDescription {
                  font-size: 10px;
                }
              }
            }
          }

          &Open {
            width: 180px;
          }

          &Open .content,
          .content:hover {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto; // Allow interaction when visible
          }
        }
      }

      &Icon {
        width: 40px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border-radius: 5px;
        background: #00b840;

        & > button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }

      &:disabled {
        color: #2e2e2e;
      }
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-style: normal;
        font-family: Inter, sans-serif;
      }

      &WithoutItalicStyle {
        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
          font-style: italic;
          font-family: Inter, sans-serif;
        }
      }
    }

    &Label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.dropdownMultiline {
  div > div {
    white-space: normal !important;
  }
}

.modelSelectorDropdown {
  &Label {
    position: relative;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    min-height: 96px;

    &Title {
      font-size: 16px;
    }

    &Desc {
      word-wrap: normal;
      white-space: normal;
      font-weight: normal;
      font-size: 14px;
      padding: 2px 0;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.bottom {
  max-height: 300px !important;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  flex-grow: 1; /* Chatbox grows/shrinks */
  min-height: 30vh; /* Ensures it never disappears */
  max-height: 70vh; /* Shrinks when textarea expands */
  overflow-y: auto;
  transition: max-height 0.2s ease-in-out;

  .chat {
    height: calc(100vh - 375px);
    margin: 0px !important;
    padding: 24px 19px;
    margin-top: 20px;
    overflow-y: scroll;
    border-radius: 4px 4px 0px 0px;
    background: #fff;

    &Message {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      max-width: 85%;

      &User {
        background-color: #f7f7f7;
        box-sizing: border-box;
        padding: 12px 20px 12px 20px;
        display: block;
      }

      &System {
        &AIIcon {
          width: 38px;

          & > img {
            width: 38px;
            height: 38px;
          }
        }

        & .chatMessageText {
          padding: 8px 12px 5px 12px;
        }

        & .systemGeneratingResponse {
          color: #2e2e2e80;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;

          img {
            margin-right: 4px;
            animation: spin 2s linear infinite;
          }
        }
      }

      &Error {
        &Text {
          color: #de3b3b;
          display: flex;
          gap: 10px;
          align-items: center;

          &Icon {
            stroke-width: 1px;
            stroke: #de3b3b;
            * {
              fill: #de3b3b;
            }
          }
        }
      }

      & > img {
        width: 73px;
        height: 68px;
        border-radius: 6px;
        margin-top: 10px;
      }

      &Text {
        flex: 1;
        color: #2e2e2e;
        font-family: Inter;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;

        & > p:first-child {
          margin-top: 0;
        }
      }

      &Controls {
        width: 100%;
        padding-left: 50px;
        display: flex;
        gap: 10px;
      }
    }

    &Placeholder {
      & > p {
        &:first-child {
          margin-bottom: 25px;
        }
        color: rgba(0, 0, 0, 0.5);
        font-family: Inter;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 22px;
        margin: 0 0 5px 0;
      }
    }
  }

  .bottomSection {
    height: 50px;
    display: flex;
    padding: 10px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    align-items: center;

    &Tooltip {
      font-family: Inter;
      width: 490px;
      height: 350px;
      left: 130px;
      position: absolute;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: 5px;

      top: 1px;
      opacity: 0; /* start invisible */
      transition: opacity 0.3s ease-out;
      animation: slideUp 1.5s ease-in-out forwards; /* 1s duration; adjust as needed */

      &::after {
        content: "";
        width: 14.83px;
        height: 14.85px;
        position: absolute;
        top: 341px;
        left: 230px;
        border: solid #e0e0e0;
        border-width: 0 1px 1px 0; // This creates the right and bottom borders
        transform: rotate(45deg); // Rotates the box to form an arrow
        background-color: white; // Matches the container background
      }
      &Top {
        padding: 19px 22px 0px 22px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &Content {
        padding: 10px 22px 10px 22px;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid #e0e0e0;
      }

      &Footer {
        display: flex;
        align-items: center;
        height: 57px;
        padding-left: 19px;

        & button {
          border: none;
          border-radius: 5px;
          background-color: #00b840;
          cursor: pointer;
          color: white;
          width: 66px;
          height: 34px;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: center;
        }
      }
    }
    & > img {
      display: inline-block;
      position: relative;
      left: 7px;
      top: 3px;
    }
  }
}

.bottomText {
  color: rgba(46, 46, 46, 0.5);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.bottomSubtext {
  color: rgba(46, 46, 46, 0.5);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
}

.textareaFont {
  &::placeholder {
    font-size: 16px !important;
  }
}

.previewWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 98%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  height: 40px;
  white-space: nowrap;
  padding: 10px 20px;
  color: #7f7f7f;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & > div {
    position: relative;

    & > img {
      cursor: default;
      border-radius: 6px;
      width: 58px;
      height: 54px;
      object-fit: cover;
    }
  }

  .clearFile {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    width: 13px;
    height: 13px;
    background: #000;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
    }
  }

  .clearDocument {
    position: absolute;
    background: #000;
    top: -10px;
    right: -5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    top: 0; /* starting position */
    opacity: 0; /* fully invisible */
  }
  80% {
    //top: -290px; /* final position */
    opacity: 0; /* still invisible */
  }
  100% {
    top: -365px; /* same position */
    opacity: 1; /* fully visible */
  }
}

:global(.playgroundns .ant-select-selector) {
  height: 33px !important;
}

:global(.playgroundns .ant-select-arrow) {
  padding-top: 1px !important;
}

:global(.playgroundns .ant-select-item-option) {
  padding: 5px;
}

.warningIcon {
  stroke-width: 1px;
  stroke: #ffb02f;
  * {
    fill: #ffb02f;
  }
}

.clearDropdowns {
  margin-left: 15px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.addDisclaimer {
  &Tooltip {
    font-family: Inter;
    width: 400px;
    height: 235px;
    right: 48px;
    position: absolute;
    background-color: white;
    border: 1px solid #ffb02f;
    border-radius: 5px;
    top: -230px !important;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    animation: slideUp 1.5s ease-in-out forwards; /* 1s duration; adjust as needed */

    &::after {
      content: "";
      width: 14.83px;
      height: 14.85px;
      position: absolute;
      top: 226px;
      right: 10px;
      border: solid #ffb02f;
      border-width: 0 1px 1px 0; // This creates the right and bottom borders
      transform: rotate(45deg); // Rotates the box to form an arrow
      background-color: white; // Matches the container background
    }
    &Top {
      padding: 19px 22px 0px 22px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &Content {
      padding: 10px 22px 10px 22px;
      font-size: 16px;
      font-weight: 400;
      border-bottom: 1px solid #e0e0e0;
    }

    &Footer {
      display: flex;
      align-items: center;
      height: 57px;
      padding-left: 19px;

      & button {
        border: none;
        border-radius: 5px;
        background-color: #ffb02f;
        cursor: pointer;
        color: white;
        width: 66px;
        height: 34px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
