.formWrapper {
  font-size: 16px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .reportButton {
      cursor: pointer;
      border: none;
      color: #00b840;
      background: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      width: fit-content;
      height: 40px;
      text-decoration: underline;
    }

    .label {
      font-size: 20px;
      font-weight: 500;
      line-height: 140%;
      text-transform: capitalize;
      margin-left: -2px;
      letter-spacing: -0.12px;
    }

    .viewOutputButton {
      color: #00b840;
      cursor: pointer;
      text-decoration: underline;
    }

    .viewOutputButtonDisabled {
      color: #b6b6b6;
      cursor: not-allowed;
      text-decoration: underline;
    }

    .textareaWrapper {
      padding: 28px 24px;
      border: 1px solid rgb(224, 224, 224);
      min-height: 51vh;
      height: 51vh;
      background-color: #fff;
      overflow-y: auto;

      img {
        cursor: pointer;
        margin-top: 10px;
      }
    }

    .textarea {
      padding: 0px !important;
      line-height: 22px;
      border-width: 0px !important;

      &Submitting {
        color: #2e2e2e;
        font-style: italic;
      }

      &:disabled {
        color: #2e2e2e;
      }

      &:focus {
        outline: none !important;
        border: none !important;
        box-shadow: none;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  .uploadButtonBlock {
    border: 1px solid rgb(224, 224, 224);
    padding: 8px 10px;

    &Button {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      border: #e0e0e0 1px solid;
      border-radius: 5px;
      height: 30px;
      padding: 10px;
      width: 150px;
      justify-content: space-between;
    }

    .uploadButtonWrapper {
      position: relative;
      display: inline-block;
      height: 30px;
      overflow: hidden;
      cursor: pointer;
      vertical-align: top;
    }
  }
}

.previewWrapper {
  position: relative;
  width: fit-content;
  padding: 20px;
  gap: 30px;
  display: flex;
  flex-direction: row;

  &:empty {
    padding: 0;
  }

  & > div {
    position: relative;

    & > img {
      cursor: default;
      border-radius: 6px;
      width: 58px;
      height: 54px;
      object-fit: cover;
    }
  }

  .clearFile {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    width: 13px;
    height: 13px;
    background: #000;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-top: 0 !important;
    }
  }
}
