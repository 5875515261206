.donorContainer {
  height: 100%;
  min-height: 600px;

  h2 {
    margin-top: 96px;
    font-family: Inter;
    font-size: 34px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  &CardsContainer {
    margin: 38px auto;
    display: flex;
    flex-wrap: wrap;
    width: 650px;
    gap: 19px;

    > div {
      cursor: pointer;
      background-color: #fff;
      width: 308px;
      height: 196px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #00b84080;
      border-radius: 5px;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .comingSoon {
        position: absolute;
        top: 11px;
        left: 15px;

        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #4e4e4e;
        background-color: #f6f6f6;
        border-radius: 4px;
        padding: 0 7px 0 8px;
      }
    }

    .disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.btnDiv {
  margin: 0;
  width: 100%;
  position: fixed;
  bottom: 0;

  .twoBtnDiv {
    padding-inline: 28px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    height: 90px;
    position: inherit;
    bottom: 0px;

    .goBackBtnDiv {
      padding-top: 22px;
      width: 100%;
      margin-left: 15px;

      .goBtn {
        height: 50px;
        width: 134px;
        color: #f8ebeb;
        background-color: #e6e6e6;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        font-size: 20px;
        flex-direction: row;
        // margin-top: 60px;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
          margin-top: 1px;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .twoBtnDiv {
      padding-inline: 20px;
      margin-bottom: 0;
      .goBtn {
        height: 40px;
        width: 114px;
      }
      .saveBtn {
        height: 40px;
        width: 114px;
      }
    }
  }
}
