.gcfCongratulationContainer {
  height: 80vh;
  max-width: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: -71px;
  margin: 0 auto;

  .congratulationBox {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 40px 10px;
    width: 55vh;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
      width: auto;
      height: auto;
    }

    & > img {
      align-self: center;
    }

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 20px;
      color: #000000;
      margin: 20px;
      text-align: center;
    }

    .firstParaContainer {
      max-width: 400px;
      margin: 0 auto;
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #2e2e2e;
        margin: 10px 0px;
      }
    }

    .exportPdfContainer {
      max-width: 430px;
      margin: 0 auto;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        margin: 5px;
        color: #2e2e2e;
      }
    }
  }

  .evaluateBox {
    width: 50vw;
    height: auto;
    padding: 27px 43px 70px;

    .firstParaContainer {
      max-width: initial;
    }
  }

  .clickableText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    color: #00b840;
    cursor: pointer;
  }

  // .goBtn {
  //   // height: 50px;
  //   // width: 80px;
  //   color: #2e2e2e;
  //   background: #ececf1;
  //   border-radius: 5px;
  //   cursor: pointer;
  //   border: none;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 5px;
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 27px;
  //   width: 116px;
  //   height: 40px;
  //   margin-top: 20px;

  // }
}
.btnDiv {
  margin: 0;
  width: 100%;
  .twoBtnDiv {
    padding-inline: 28px;
    width: 100%;
    border-top: 1px solid #e0e0e0;
    background-color: #ffffff;
    height: 90px;
    position: inherit;
    display: flex;
    align-items: center;
    bottom: 0;

    @media only screen and (max-width: 360px) {
      padding-inline: 10px;
    }
    .goBackBtnDiv {
      width: 100%;
      margin-left: 15px;
      display: flex;
      justify-content: right;

      .goBtn {
        height: 50px;
        width: 134px;
        margin-left: 10px;
        color: #f8ebeb;
        background-color: #e6e6e6;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        font-size: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
          margin-top: 1px;
        }
      }
    }
    .saveBtn {
      color: #2e2e2e;
      background-color: #e6e6e6;
      height: 50px;
      width: 134px;
      border: none;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
}
.exportButtonsWrapper {
  display: flex;
  gap: 13px;
  align-items: center;
}

.exportButtonsImg {
  padding: 3px 25px 3px 11px;
  text-transform: capitalize;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;

  border-radius: 5px;
  border: 1px solid #dde2e4;
  background: var(--primary-white, #fff);

  & > p {
    color: #2e2e2e;
    font-family: Inter;
    font-weight: 500;
    line-height: 27px;
    margin: 0;
  }
}

.exportToWordBtn {
  border-radius: 5px;
  border: 1px solid #dde2e4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: transparent;
  padding: 7px;
  cursor: pointer;
  &:last-child {
    margin-right: 40px;
  }
}
.modalBody {
  :global(.ant-modal-body) {
    // padding: 5px 18px 22px;
  }
  :global(.ant-modal-header) {
    margin: 0;
  }
  :global(.ant-modal-title) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 17px;

    h1 {
      font-family: Inter;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }
  }
  :global(.ant-divider) {
    display: none;
  }

  :global(.ant-modal-close) {
    top: 19px;
  }
  :global(.ant-modal-content) {
    padding-right: 0;
    padding-top: 0;
    padding-left: 0;
    background-color: #f6f6f6;
  }
}
.modelText {
  padding: 5px 70px 22px 20px;

  h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-top: 34px;
  }

  p {
    color: #2e2e2e;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
}
:global(.ant-modal .ant-modal-content) {
  height: auto;
  // width: 657px;
  border-radius: 8px;
  border: 1px solid #f6f6f6;
  background-color: #ffffff;
  :global(.ant-modal-title) {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    margin-bottom: 0;
  }
  :global(.ant-divider-horizontal) {
    margin: 0;
  }
}

.evaluateBox {
  height: 80vh;
  max-width: 100vw;

  .congratulationBox {
    width: 100%;
    height: 100%;
    max-width: 720px;
    padding: 27px 43px 62px;

    .firstParaContainer {
      max-width: initial;
      margin: 0 auto;
    }
  }
}

.enterEmailWrap {
  width: fit-content;
  height: fit-content;

  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 9px;
  color: #00b840;
}

.enterEmail {
  width: fit-content;
  height: fit-content;

  display: flex;
  align-items: center;
  gap: 10px;
}

.emailInput {
  width: 145px;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #2e2e2e;
  padding-left: 8px;

  font-size: 12px;

  &:focus,
  &:active,
  &:visited {
    border: 1px solid #e0e0e0;
    outline: none;
  }
}

.sendIcon {
  cursor: pointer;
}

.summaryInfo {
  padding: 14px 18px 9px 24px;
  width: 100%;
  height: 90px;
  background-color: rgb(247, 247, 247);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
    color: #000;

    strong {
      font-weight: 600;
    }
  }
}

.topLine {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  background-color: rgb(0, 184, 64);
  height: 8px;
  border-radius: 8px 8px 0px 0px;
}
